import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { getI18n, Trans, useTranslation } from "react-i18next";
import {
  FormControl,
  Link as MUILink,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Link, navigate } from "gatsby";

// While adding/removing langs, don't forget to update list on gatsby-node.js
const sites = new Map([
  ["ja", "https://bogunov.co.jp/"],
  ["en", "https://bogunov.uk/"],
]);

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSidebarOpen: () => void;
  // pages: {
  //   landings: Array<PageItem>;
  //   company: Array<PageItem>;
  //   account: Array<PageItem>;
  //   secondary: Array<PageItem>;
  //   blog: Array<PageItem>;
  //   portfolio: Array<PageItem>;
  // };
  colorInvert?: boolean;
}

const Topbar = ({
  onSidebarOpen,
  // pages,
  colorInvert = false,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const i18n = getI18n();
  const theme = useTheme();
  const { mode } = theme.palette;

  const [lang, setLang] = React.useState(i18n.language);

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value !== i18n.language) {
      navigate(sites.get(event.target.value) || "");
    }
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <MUILink
        underline="none"
        variant="h5"
        component="a"
        href="/"
        color={colorInvert ? "common.white" : "text.primary"}
        data-aos={"fade-up"}
        sx={{
          fontWeight: 600,
        }}
      >
        <Trans>Bogunov</Trans>
      </MUILink>
      <Box
        sx={{
          display: { xs: "none", md: "flex", "& a": { fontSize: "1rem" } },
        }}
        alignItems={"center"}
      >
        {/* <Box marginLeft={4}>
          <NavItem
            title={"Portfolio"}
            id={"portfolio-pages"}
            items={portfolioPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        <Box marginLeft={4}>
          <Button
            variant="text"
            component={Link}
            to="/"
            activeStyle={{ fontWeight: 700 }}
            size="large"
            sx={{ color: colorInvert ? "common.white" : "text.primary" }}
          >
            <Trans>Works</Trans>
          </Button>
        </Box>
        <Box marginLeft={4}>
          <Button
            variant="text"
            component={Link}
            to="/about-us"
            activeStyle={{ fontWeight: 700 }}
            size="large"
            sx={{ color: colorInvert ? "common.white" : "text.primary" }}
          >
            <Trans>About us</Trans>
          </Button>
        </Box>
        <Box marginLeft={4}>
          <Button
            variant="text"
            component={Link}
            to="/services"
            activeStyle={{ fontWeight: 700 }}
            size="large"
            sx={{ color: colorInvert ? "common.white" : "text.primary" }}
          >
            <Trans>Services</Trans>
          </Button>
        </Box>
        <Box marginLeft={4}>
          <Button
            variant="text"
            component={Link}
            to="/products"
            activeStyle={{ fontWeight: 700 }}
            size="large"
            sx={{ color: colorInvert ? "common.white" : "text.primary" }}
          >
            <Trans>Our products</Trans>
          </Button>
        </Box>
        <Box marginLeft={4}>
          <Button
            variant="text"
            component={Link}
            to={`/blog/${i18n.language}`}
            activeStyle={{ fontWeight: 700 }}
            partiallyActive
            size="large"
            sx={{ color: colorInvert ? "common.white" : "text.primary" }}
          >
            <Trans>Blog</Trans>
          </Button>
        </Box>
        <Box marginLeft={4}>
          <Button
            variant="text"
            component={Link}
            to="/contacts"
            activeStyle={{ fontWeight: 700 }}
            size="large"
            sx={{ color: colorInvert ? "common.white" : "text.primary" }}
          >
            <Trans>Contacts</Trans>
          </Button>
        </Box>
        <Box marginLeft={4}>
          <FormControl fullWidth>
            <Select
              size="small"
              value={lang}
              onChange={handleChange}
              sx={{
                color: colorInvert ? "common.white" : "text.primary",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: colorInvert ? "#ffffff3b" : "#0000003b",
                },
                "& .MuiSvgIcon-root": {
                  color: colorInvert ? "#ffffff8a" : "#0000008a",
                },
              }}
            >
              <MenuItem value={"en"}>{t("EN")}</MenuItem>
              <MenuItem value={"ja"}>{t("JA")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {/*<Box marginLeft={4}>*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    color="primary"*/}
        {/*    component="a"*/}
        {/*    target="blank"*/}
        {/*    href="https://mui.com/store/items/the-front-landing-page/"*/}
        {/*    size="large"*/}
        {/*  >*/}
        {/*    Buy now*/}
        {/*  </Button>*/}
        {/*</Box>*/}
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }} alignItems={"center"}>
        <Box marginRight={2}>
          <FormControl fullWidth>
            <Select
              size="small"
              value={lang}
              onChange={handleChange}
              sx={{
                color: colorInvert ? "common.white" : "text.primary",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: colorInvert ? "#ffffff3b" : "#0000003b",
                },
                "& .MuiSvgIcon-root": {
                  color: colorInvert ? "#ffffff8a" : "#0000008a",
                },
              }}
            >
              <MenuItem value={"en"}>{t("EN")}</MenuItem>
              <MenuItem value={"ja"}>{t("JA")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: colorInvert ? "#ffffff3b" : "#0000003b",
            color: colorInvert ? "common.white" : "text.primary",
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;
