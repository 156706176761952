import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { getI18n, Trans, useTranslation } from "react-i18next";

import NavItem from "./components/NavItem";
import { Link } from "gatsby";

const SidebarNav = ({ pages }: any): JSX.Element => {
  const { t } = useTranslation();
  const i18n = getI18n();
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <Button
            variant="text"
            component={Link}
            activeStyle={{ fontWeight: 700 }}
            to="/"
            size="large"
            sx={{ color: "text.primary" }}
          >
            <Trans>Works</Trans>
          </Button>
        </Box>
        <Box>
          <Button
            variant="text"
            component={Link}
            activeStyle={{ fontWeight: 700 }}
            to="/about-us"
            size="large"
            sx={{ color: "text.primary" }}
          >
            <Trans>About us</Trans>
          </Button>
        </Box>
        <Box>
          <Button
            variant="text"
            component={Link}
            activeStyle={{ fontWeight: 700 }}
            to="/services"
            size="large"
            sx={{ color: "text.primary" }}
          >
            <Trans>Services</Trans>
          </Button>
        </Box>
        <Box>
          <Button
            variant="text"
            component={Link}
            activeStyle={{ fontWeight: 700 }}
            to="/products"
            size="large"
            sx={{ color: "text.primary" }}
          >
            <Trans>Our products</Trans>
          </Button>
        </Box>
        <Box>
          <Button
            variant="text"
            component={Link}
            activeStyle={{ fontWeight: 700 }}
            partiallyActive
            to={`/blog/${i18n.language}`}
            size="large"
            sx={{ color: "text.primary", justifyContent: "start" }}
          >
            <Trans>Blog</Trans>
          </Button>
        </Box>
        <Box>
          <Button
            variant="text"
            component={Link}
            activeStyle={{ fontWeight: 700 }}
            to="/contacts"
            size="large"
            sx={{ color: "text.primary" }}
          >
            <Trans>Contacts</Trans>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarNav;
