import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Trans, useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { StaticImage } from "gatsby-plugin-image";

const Footer = (): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box display="flex" flexDirection="row">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mr="2rem"
            >
              <Link
                underline="none"
                variant="h5"
                component="a"
                href="/"
                color="text.primary"
                data-aos={"fade-up"}
                sx={{
                  fontWeight: 600,
                }}
              >
                <Trans>BOGUNOV</Trans>
              </Link>
              <Box mb={{ xs: "1rem", sm: 0 }}>
                <IconButton
                  target="_blank"
                  aria-label="linkedin"
                  href="https://www.linkedin.com/in/airinhoshino/"
                  sx={{ color: "#4e4e4e" }}
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  target="_blank"
                  aria-label="facebook"
                  href="https://www.facebook.com/Bogunovsoft/"
                  sx={{ color: "#4e4e4e" }}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  target="_blank"
                  aria-label="youtube"
                  href="https://www.youtube.com/user/elviss24rus"
                  sx={{ color: "#4e4e4e" }}
                >
                  <YouTubeIcon />
                </IconButton>
              </Box>
            </Box>
            <StaticImage
              src="./security_action.webp"
              height={95}
              alt="security action logo"
              style={{ marginTop: "-0.6rem" }}
            />
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
          >
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/"
                color="text.primary"
                variant={"subtitle2"}
              >
                <Trans>Works</Trans>
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/about-us"
                color="text.primary"
                variant={"subtitle2"}
              >
                <Trans>About us</Trans>
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/services"
                color="text.primary"
                variant={"subtitle2"}
              >
                <Trans>Services</Trans>
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/products"
                color="text.primary"
                variant={"subtitle2"}
              >
                <Trans>Our products</Trans>
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/blog"
                color="text.primary"
                variant={"subtitle2"}
              >
                <Trans>Blog</Trans>
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/contacts"
                color="text.primary"
                variant={"subtitle2"}
              >
                <Trans>Contacts</Trans>
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="text.secondary"
          gutterBottom
          sx={{ fontWeight: 400, mt: "1.5rem" }}
        >
          &copy; {t("Bogunov Inc. 2022. All rights reserved")}
        </Typography>
        <Typography
          align={"center"}
          variant={"caption"}
          color="text.secondary"
          component={"p"}
          sx={{ wordBreak: "keep-all" }}
        >
          <Trans>
            When you visit or interact with our sites, services or tools, we or
            our authorised service providers may use cookies for storing
            information to help provide you with a better, faster and safer
            experience and for marketing purposes.
          </Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
